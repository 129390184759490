
      import Chatbot from "https://cdn.jsdelivr.net/npm/flowise-embed/dist/web.js";
      function getFromLocalStorage(localStorageKey = "user") {
        return JSON.parse(localStorage.getItem(localStorageKey)) || [];
      }
      const user = getFromLocalStorage();
      const apikey = user.apitoken || "";
      const sessionId = user.id || "";
      Chatbot.init({
        chatflowid: "f554a51e-f06e-4581-8ef0-713a445247ef",
        apiHost: "https://flowise.e2tech.com",
        chatflowConfig: {
          sessionId: sessionId,
          vars: { xapikey: apikey },
        },
        theme: {
          button: {
            backgroundColor: "#3B81F6",
            right: 20,
            bottom: 20,
            size: 48, // small | medium | large | number
            dragAndDrop: true,
            iconColor: "white",
            customIconSrc:
              "https://raw.githubusercontent.com/walkxcode/dashboard-icons/main/svg/google-messages.svg",
          },
          tooltip: {
            showTooltip: true,
            tooltipMessage: "Hi There 👋!",
            tooltipBackgroundColor: "black",
            tooltipTextColor: "white",
            tooltipFontSize: 16,
          },
          chatWindow: {
            showTitle: true,
            title: "e2integrate Bot",
            titleAvatarSrc:
              "https://raw.githubusercontent.com/walkxcode/dashboard-icons/main/svg/google-messages.svg",
            showAgentMessages: true,
            welcomeMessage: "Hello! Welcome to e2integrate",
            errorMessage: "Sorry! An error has occured. Please try again.",
            backgroundColor: "#ffffff",
            height: 820,
            width: 1180,
            fontSize: 16,
            poweredByTextColor: "#303235",
            botMessage: {
              backgroundColor: "#f7f8ff",
              textColor: "#303235",
              showAvatar: true,
              avatarSrc: "/layout/images/logo-dark.png",
              // avatarSrc: 'https://raw.githubusercontent.com/zahidkhawaja/langchain-chat-nextjs/main/public/parroticon.png'
            },
            userMessage: {
              backgroundColor: "#3B81F6",
              textColor: "#ffffff",
              showAvatar: true,
              avatarSrc:
                "https://raw.githubusercontent.com/zahidkhawaja/langchain-chat-nextjs/main/public/usericon.png",
            },
            textInput: {
              placeholder: "Type your question",
              backgroundColor: "#ffffff",
              textColor: "#303235",
              sendButtonColor: "#3B81F6",
              maxChars: 80,
              maxCharsWarningMessage:
                "You exceeded the characters limit. Please input less than 80 characters.",
              autoFocus: true, // If not used, autofocus is disabled on mobile and enabled on desktop. true enables it on both, false disables it on both.
              sendMessageSound: true,
              // sendSoundLocation: "send_message.mp3", // If this is not used, the default sound effect will be played if sendSoundMessage is true.
              receiveMessageSound: true,
              // receiveSoundLocation: "receive_message.mp3", // If this is not used, the default sound effect will be played if receiveSoundMessage is true.
            },
            feedback: {
              color: "#303235",
            },
            footer: {
              textColor: "#303235",
              text: "Powered by",
              company: "e2integrate",
              companyLink: "https://up2tomv8.e2tech.com",
            },
          },
        },
      });
    